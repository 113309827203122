import { tiles, defaultTiles, lastTile } from "./constants";
import { mySplit } from "./utils";

let element2;
let element1 = (element2 = {
  x: null,
  y: null,
});

for (let i = 0; i < tiles.length; i++) {
  document.querySelectorAll('.puzzle div')[i].classList.add(defaultTiles[i]);
}

function transition(cell1, cell2, position) {
  cell1.classList.add('transition');
  cell2.classList.add('transition');
  element1.x =
    cell1.getBoundingClientRect().left - cell2.getBoundingClientRect().left;
  element1.y =
    cell2.getBoundingClientRect().top - cell1.getBoundingClientRect().top;
  element2.x =
    cell2.getBoundingClientRect().left - cell1.getBoundingClientRect().left;
  element2.y =
    cell1.getBoundingClientRect().top - cell2.getBoundingClientRect().top;
  cell1.style.transform = `translate(${element1.x}px, ${-element1.y}px)`;
  cell2.style.zIndex = '-1';
  cell2.style.transform = `translate(${
    (position === 'right' || position === 'left' ? '-' : '') + element2.x
  }px, ${element2.y}px)`;
  cell1.style.zIndex = '1';
  setTimeout(() => {
    swapTiles(cell1, cell2);
    cell1.classList.remove('transition');
    cell2.classList.remove('transition');
    cell2.style.removeProperty('transform');
    cell2.style.removeProperty('z-index');
    cell1.style.removeProperty('transform');
    cell1.style.removeProperty('z-index');
    if (cell1.getAttribute('style') == '') {
      cell1.removeAttribute('style');
    }
    if (cell2.getAttribute('style') == '') {
      cell2.removeAttribute('style');
    }
  }, 100);
}

function swapTiles(cell1, cell2) {
  const temp = cell1.className;
  cell1.className = cell2.className;
  cell2.className = temp;
}

function check_win() {
  let res = true;
  const tiles = document.querySelectorAll("*[class^='tile']");
  for (let i = 0; i < tiles.length; i++) {
    let toCompare = tiles[i].className;
    if (toCompare.includes('transition')) {
      toCompare = toCompare.split(' ')[0];
    }
    if (toCompare !== defaultTiles[i]) {
      res = false;
    }
  }
  return res;
}

function clickTile(clicked_id) {
  const splitID = mySplit(clicked_id);
  const row = parseInt(splitID[1]);
  const column = parseInt(splitID[2]);
  const currentCell = 'cell' + row + column;
  const currentCellID = document.getElementById(currentCell);
  const tile = currentCell.className;
  const rightCellID = document.getElementById('cell' + row + (column + 1));
  const leftCellID = document.getElementById('cell' + row + (column - 1));
  const aboveCellID = document.getElementById('cell' + (row - 1) + column);
  const belowCellID = document.getElementById('cell' + (row + 1) + column);
  if (tile === lastTile) {
    return;
  }
  if (column < Math.sqrt(tiles.length)) {
    if (rightCellID.className == lastTile) {
      transition(currentCellID, rightCellID, 'right');
    }
  }
  if (column > 1) {
    if (leftCellID.className == lastTile) {
      transition(currentCellID, leftCellID, 'left');
    }
  }
  if (row > 1) {
    if (aboveCellID.className == lastTile) {
      transition(currentCellID, aboveCellID, 'above');
    }
  }
  if (row < Math.sqrt(tiles.length)) {
    if (belowCellID.className == lastTile) {
      transition(currentCellID, belowCellID, 'below');
    }
  }
}

export {swapTiles, check_win, clickTile};