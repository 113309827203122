// under this line ---- constants that are used in the game

const delay = 5000; // 5 seconds

const lastTile = 'tile9';

const messages = [
      'Relatives can share many qualities, including conditions driven in part by type 2 inflammation.<sup>1</sup> Many may not realize that different diseases in their families can have a similar underlying cause.<sup>1</sup>',
      'Disease names can evolve over time. Technical terms for diseases like “atopic,” “allergic,” or “eosinophilic” have all been used to describe different types of asthma caused in part by type 2 inflammation.<sup>1</sup>',
      'People who have diseases like asthma or atopic dermatitis can have shared experiences, such as unpredictable worsening of symptoms, possibly caused in part by type 2 inflammation that may be connecting them.<sup>1,29</sup>',
      'Certain allergies, atopic dermatitis (or eczema) and asthma can all share a contributing factor: type 2 inflammation.<sup>1</sup>',
      'Chronic rhinosinusitis with nasal polyps (CRSwNP) and atopic dermatitis (or eczema) are also unexpectedly connected by a common trait: type 2 inflammation.<sup>1</sup>',
      'People who have one disease caused in part by type 2 inflammation are at increased risk of having another.<sup>1</sup>',
      'Type 2 inflammation is an immune response, occurring when the body communicates that there is a trigger for inflammation, like an allergic reaction.<sup>1</sup>',
      'The way type 2 inflammation manifests in different people varies widely, depending on factors like genetics, environmental triggers and tissue damage.<sup>1,21</sup>',
      'A group of common factors—such as environmental triggers and genetic predispositions—determine how and when diseases with underlying type 2 inflammation appear.<sup>18,21</sup>',
];

const tiles = document.querySelectorAll("*[id^='cell']");

const defaultTiles = Array.from(
  {
    length: tiles.length,
  },
  (_, i) => i + 1
).map((i) => 'tile' + i);

const countDown = 90;

// under this line ---- constants that select DOM elements

const howToPlayButton = document.querySelector('.how-to-play-btn');
const howToButton = document.querySelector('.how-to-btn');
const closeButton = document.querySelector('.close-button');
const revealSolutionButton = document.querySelector('.reveal-solution-btn');
const startBtn = document.querySelector('.start-btn');
const previousButton = document.querySelector('.previous-btn');
const nextButton = document.querySelector('.next-btn');
const puzzleSolution = document.querySelector('.puzzle-solution');
const puzzleContainer = document.querySelector('.puzzle');

export { delay, lastTile, messages, tiles, defaultTiles, howToPlayButton, howToButton, closeButton, revealSolutionButton, startBtn, previousButton, nextButton, puzzleSolution, countDown, puzzleContainer };
