import {
  startGameDOM,
  endGameDOM,
  showMessageDOM,
  hideMessageDOM,
  getToHowToPlayScreenDOM,
  backToMainScreenDOM,
  showDisabledMessage,
  showSolutionDOM,
  hideSolutionDOM,
} from './domManipulation.js';
import {
  formatTime,
  change_message,
  change_bg,
  mySplit,
  resizeImage,
} from './utils.js';
import { check_win, clickTile, swapTiles } from './puzzle.js';
import {
  delay,
  defaultTiles,
  howToButton,
  closeButton,
  revealSolutionButton,
  startBtn,
  previousButton,
  nextButton,
  tiles,
  countDown,
  lastTile,
} from './constants.js';
import { firebaseFn } from './firebase.js';

let defaultNumbering, randomNumbering;

function new_game() {
  let currentIndex = tiles.length - 1;
  for (const _element of tiles) {
    const row1 = (currentIndex % Math.sqrt(tiles.length)) + 1;
    const column1 = Math.floor(currentIndex / Math.sqrt(tiles.length)) + 1;
    const row2 = Math.floor(Math.random() * Math.sqrt(tiles.length) + 1);
    const column2 = Math.floor(Math.random() * Math.sqrt(tiles.length) + 1);
    const cell1ID = document.getElementById('cell' + row1 + column1);
    const cell2ID = document.getElementById('cell' + row2 + column2);
    if (
      cell1ID.style.hasOwnProperty('transform') ||
      cell1ID.style.hasOwnProperty('z-index')
    ) {
      cell1ID.style.removeProperty('transform');
      cell1ID.style.removeProperty('z-index');
      cell1ID.classList.remove('transition');
      if (cell1ID.getAttribute('style') == '') {
        cell1ID.removeAttribute('style');
      }
    } else if (
      cell2ID.style.hasOwnProperty('transform') ||
      cell2ID.style.hasOwnProperty('z-index')
    ) {
      cell2ID.style.removeProperty('transform');
      cell2ID.style.removeProperty('z-index');
      cell2ID.classList.remove('transition');
      if (cell2ID.getAttribute('style') == '') {
        cell2ID.removeAttribute('style');
      }
    }
    swapTiles(cell1ID, cell2ID);
    currentIndex--;
  }
  isSolvable();
}

function isSolvable() {
  defaultNumbering = defaultTiles.map(function (tile) {
    return parseInt(tile.replace('tile', ''));
  });

  randomNumbering = Array.from(
    document.querySelectorAll("*[class^='tile']")
  ).map(function (tile) {
    return parseInt(tile.className.replace('tile', ''));
  });

  let inversions = 0;
  const rowOfEmptyTile = parseInt(
    mySplit(document.getElementsByClassName(lastTile)[0].id)[1]
  );

  for (let i = 0; i <= randomNumbering.length; i++) {
    const numberOfTile = randomNumbering[i];
    const numbersAfterTile = randomNumbering.slice(i + 1);
    if (
      numbersAfterTile.some((value) => value < numberOfTile) &&
      numberOfTile !== defaultNumbering[8]
    ) {
      numbersAfterTile.forEach((value) => value < numberOfTile && inversions++);
    }
  }

  if (inversions % 2 == 0 && rowOfEmptyTile % 2 == 0) {
    //do nothing
  } else {
    new_game();
  }
}

const generalState = {
  iGameOver: true,
  gameOverListener: function (_oldVal, _newVal) {},
  set gameOver(val) {
    this.gameOverListener(this.iGameOver, val);
    this.iGameOver = val;
  },
  get gameOver() {
    return this.iGameOver;
  },
  iStartTime: null,
  set startTime(val) {
    this.iStartTime = val;
  },
  get startTime() {
    return this.iStartTime;
  },
  iPaused: false,
  pausedListener: function (_oldVal, _newVal) {},
  set paused(val) {
    this.pausedListener(this.iPaused, val);
    this.iPaused = val;
  },
  get paused() {
    return this.iPaused;
  },
  iTimeElapsedArray: [],
  set timeElapsedArray(val) {
    this.iTimeElapsedArray = val;
  },
  get timeElapsedArray() {
    return this.iTimeElapsedArray;
  },
  iMovesArray: [],
  set movesArray(val) {
    this.iMovesArray = val;
  },
  get movesArray() {
    return this.iMovesArray;
  },
  iMessageShownArray: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  set messageShownArray(val) {
    this.iMessageShownArray = val;
  },
  get messageShownArray() {
    return this.iMessageShownArray;
  },
  iWasRevealedArray: [],
  set wasRevealedArray(val) {
    this.iWasRevealedArray = val;
  },
  get wasRevealedArray() {
    return this.iWasRevealedArray;
  },
  registerListener: function (key, listener) {
    this[key] = listener;
  },
};

generalState.registerListener('gameOverListener', gameOverHandler);
generalState.registerListener('pausedListener', pauseHandler);

async function gameOverHandler(oldVal, newVal) {
  if (newVal === false) {
    console.warn('Game Started');
    resetCount();
    startGameDOM();
    resetGeneralState();
    enableTiles();
    generalState.startTime = new Date().toJSON();
    puzzleState.currentPuzzle = 1;
  }
  if (newVal === true && oldVal === false) {
    console.warn('Game Ended');
    stopCount();
    resetCount();
    endGameDOM();
    disableTiles();
    console.warn(
      'Array of time spent on each puzzle: ' + generalState.timeElapsedArray,
      'Array of moves done on each puzzle: ' + generalState.movesArray,
      'Array of how many times each message was shown: ' +
        generalState.messageShownArray +
        'Array of how many times each puzzle was revealed: ' +
        generalState.wasRevealedArray
    );

    // here we need to save the data and send it to firebase
    // we need to send the timeElapsedArray, movesArray, messageShownArray
    await firebaseFn(
      generalState.startTime,
      new Date().toJSON(),
      generalState.timeElapsedArray.length,
      localStorage.getItem('sessionId'),
      generalState.messageShownArray,
      generalState.wasRevealedArray,
      generalState.timeElapsedArray
    );
  }
}

function pauseHandler(oldVal, newVal) {
  if (newVal === true) {
    stopCount();
  }
  if (newVal === false && oldVal === true) {
    countTime();
  }
}

// --------------------------------------------

const puzzleState = {
  iCurrentPuzzle: 0,
  currentPuzzleListener: function (_oldVal, _newVal) {},
  set currentPuzzle(val) {
    this.currentPuzzleListener(this.iCurrentPuzzle, val);
    this.iCurrentPuzzle = val;
  },
  get currentPuzzle() {
    return this.iCurrentPuzzle;
  },
  iInProgress: true,
  inProgressListener: function (_oldVal, _newVal) {},
  set inProgress(val) {
    this.inProgressListener(this.iInProgress, val);
    this.iInProgress = val;
  },
  get inProgress() {
    return this.iInProgress;
  },
  iMessageShown: false,
  messageShownListener: function (_oldVal, _newVal) {},
  set messageShown(val) {
    this.messageShownListener(this.iMessageShown, val);
    this.iMessageShown = val;
  },
  get messageShown() {
    return this.iMessageShown;
  },
  iTimeElapsed: 0,
  set timeElapsed(val) {
    this.iTimeElapsed = val;
  },
  get timeElapsed() {
    return this.iTimeElapsed;
  },
  iMovesDone: 0,
  movesDoneListener: function (_oldVal, _newVal) {},
  set movesDone(val) {
    this.movesDoneListener(this.iMovesDone, val);
    this.iMovesDone = val;
  },
  get movesDone() {
    return this.iMovesDone;
  },
  registerListener: function (key, listener) {
    this[key] = listener;
  },
  iIsCheckingForWin: false,
  set isCheckingForWin(val) {
    this.iIsCheckingForWin = val;
  },
  get isCheckingForWin() {
    return this.iIsCheckingForWin;
  },
};

puzzleState.registerListener('currentPuzzleListener', currentPuzzleHandler);
puzzleState.registerListener('inProgressListener', inProgressHandler);
puzzleState.registerListener('movesDoneListener', movesDoneHandler);
puzzleState.registerListener('messageShownListener', messageShownHandler);

function messageShownHandler(oldVal, newVal) {
  if (newVal === true) {
    revealSolutionButton.classList.add('disabled');
  } else {
    revealSolutionButton.classList.remove('disabled');
  }
}

function inProgressHandler(oldVal, newVal) {
  if (newVal === false && oldVal === true) {
    stopCount();
    generalState.timeElapsedArray = [
      ...generalState.timeElapsedArray,
      90 - puzzleState.timeElapsed,
    ];
    generalState.movesArray = [
      ...generalState.movesArray,
      puzzleState.movesDone,
    ];
    showSolutionDOM();
    setTimeout(() => {
      hideSolutionDOM();
      showMessageDOM();
      puzzleState.messageShown = true;
      if (puzzleState.currentPuzzle == 9) {
        nextButton.textContent = 'Complete';
      }
    }, delay);
    generalState.messageShownArray[puzzleState.currentPuzzle - 1]++;
  }
}

function currentPuzzleHandler(oldVal, newVal) {
  document.querySelector('.current-puzzle').textContent = newVal;
  nextButton.textContent = 'Next';
  if (newVal !== oldVal) {
    change_message(newVal);
    change_bg(newVal);
    // also resize the image (setTimeout because it takes time to change the background image)
    setTimeout(() => {
      resizeImage();
    });
    if (generalState.timeElapsedArray[newVal - 1] !== undefined) {
      console.warn('Puzzle already played');
      puzzleState.inProgress = false;
      puzzleState.messageShown = true;
      showMessageDOM();
      generalState.messageShownArray[newVal - 1]++;
      puzzleState.movesDone = generalState.movesArray[newVal - 1];
      document.querySelector('.move-counter').textContent =
        puzzleState.movesDone;
      puzzleState.timeElapsed = generalState.timeElapsedArray[newVal - 1];
      document.querySelector('.timer').textContent = formatTime(
        puzzleState.timeElapsed
      );
    } else {
      resetPuzzleState();
      resetCount();
      countTime();
      hideMessageDOM();
      new_game();
    }
  }
}

function movesDoneHandler(oldVal, newVal) {
  if (newVal !== oldVal && puzzleState.inProgress === true) {
    document.querySelector('.move-counter').textContent = newVal; // each time moves count increase change dom text
    if (newVal !== 0 && puzzleState.inProgress === true) {
      puzzleState.isCheckingForWin = true;
      setTimeout(() => {
        if (check_win()) {
          console.warn('Puzzle finished!');
          puzzleState.inProgress = false; // stop the game
          generalState.wasRevealedArray[puzzleState.currentPuzzle - 1] = false;
        }
        puzzleState.isCheckingForWin = false;
      }, 300);
    }
  }
}

// --------------------------------------------

function resetPuzzleState() {
  puzzleState.timeElapsed = 0;
  puzzleState.movesDone = 0;
  puzzleState.messageShown = false;
  puzzleState.inProgress = true;
}

function startGame() {
  generalState.gameOver = false;
}

function resetGeneralState() {
  generalState.startTime = null;
  generalState.timeElapsedArray = [];
  generalState.movesArray = [];
}

function endGame() {
  generalState.gameOver = true;
}

function getToHowToPlayScreen() {
  if (puzzleState.inProgress === true) {
    generalState.paused = true;
  }
  getToHowToPlayScreenDOM();
}

function backToMainScreen() {
  if (generalState.paused === true && puzzleState.inProgress === true) {
    generalState.paused = false;
  }
  backToMainScreenDOM();
}

function revealSolution() {
  if (puzzleState.inProgress === true) {
    console.warn('Solution revealed!');
    generalState.paused = true;
    puzzleState.inProgress = false;
    generalState.wasRevealedArray[puzzleState.currentPuzzle - 1] = true;
  }
  // remove all the tiles classes
  tiles.forEach((tile) => {
    tile.className = '';
  });
  // add the solution classes
  tiles.forEach((tile, index) => {
    tile.classList.add(defaultTiles[index]);
  });
}

function goBack() {
  if (
    puzzleState.inProgress === false &&
    puzzleState.messageShown === true &&
    puzzleState.currentPuzzle !== 1
  ) {
    puzzleState.currentPuzzle--;
  }
}

function next() {
  if (
    puzzleState.currentPuzzle === 9 &&
    puzzleState.inProgress === false &&
    puzzleState.messageShown === true
  ) {
    endGame();
    return;
  }
  if (puzzleState.inProgress === false && puzzleState.messageShown === true) {
    puzzleState.currentPuzzle++;
  } else {
    if (puzzleState.inProgress === true) showDisabledMessage();
  }
}

// --------------------------------------------

const tileClickHandler = (tile) => {
  return () => {
    if (puzzleState.inProgress && !puzzleState.isCheckingForWin) {
      clickTile(tile.id);
      puzzleState.movesDone++;
    }
  };
};

const enableTiles = () => {
  for (const element of tiles) {
    element.addEventListener('click', tileClickHandler(element));
  }
};

const disableTiles = () => {
  for (const element of tiles) {
    element.removeEventListener('click', tileClickHandler(element));
  }
};

// --------------------------------------------

startBtn.addEventListener('click', startGame);
howToButton.addEventListener('click', getToHowToPlayScreen);
closeButton.addEventListener('click', backToMainScreen);
revealSolutionButton.addEventListener('click', revealSolution);
previousButton.addEventListener('click', goBack);
nextButton.addEventListener('click', next);

// --------------------------------------------

let once = false;
async function unload(e) {
  if (generalState.gameOver === false) {
    if (once === false) {
      console.warn(
        'Array of time spent on each puzzle: ' + generalState.timeElapsedArray,
        'Array of moves done on each puzzle: ' + generalState.movesArray,
        'Array of how many times each message was shown: ' +
          generalState.messageShownArray +
          'Array of how many times each puzzle was revealed: ' +
          generalState.wasRevealedArray
      );
      once = true;
      await firebaseFn(
        generalState.startTime,
        new Date().toJSON(),
        generalState.timeElapsedArray.length,
        localStorage.getItem('sessionId'),
        generalState.messageShownArray,
        generalState.wasRevealedArray,
        generalState.timeElapsedArray
      );
      e.preventDefault();
      e.stopPropagation();
      setTimeout(function () {
        //first, remove handler, so the beforeunload's behaviour is back to default
        window.removeEventListener('beforeunload', unload, false);
        //dispatch new beforeunload event:
        window.dispatchEvent(new Event('beforeunload'));
      }, 1000);
    }
  }
}

window.onbeforeunload = unload;

// --------------------------------------------

let totalSeconds = 0;

let interval;

function countTime() {
  interval = setInterval(() => {
    totalSeconds++;
    puzzleState.timeElapsed = countDown - totalSeconds;
    if (puzzleState.timeElapsed === 0) {
      revealSolution();
    }
    let formattedTime = formatTime(puzzleState.timeElapsed);
    console.warn(`Timer: ${formattedTime}`);
    document.querySelector('.timer').textContent = `${formattedTime}`;
  }, 1000);
}

function stopCount() {
  clearInterval(interval);
}

function resetCount() {
  totalSeconds = 0;
  document.querySelector('.timer').textContent = formatTime(countDown);
  document.querySelector('.move-counter').textContent = 0;
}

(() => {
  window.localStorage.setItem('sessionId', Date.now().toString());
  // Resize Listener
  window.addEventListener('resize', () => {
    if (generalState.gameOver) return;
    if (puzzleState.inProgress === false) return;
    // change background size of all tiles
    resizeImage();
  });
})();
