import { initializeApp } from 'firebase/app';

import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const save = httpsCallable(functions, 'save');

async function firebaseFn(
  startTime,
  endTime,
  puzzles,
  sessionId,
  messageShownArray,
  wasRevealedArray,
  timeElapsedArray,
) {
  return save({
    startTime,
    endTime,
    puzzles,
    sessionId,
    messageShownArray,
    wasRevealedArray,
    timeElapsedArray,
  })
    .then((result) => {
      // Read result of the Cloud Function.
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export { firebaseFn };
