import {
  messages,
  puzzleSolution,
  puzzleContainer,
  tiles,
} from './constants.js';

const formatTime = (secs) => {
  let seconds = secs % 60;
  let minutes = Math.floor(secs / 60);
  let formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  let formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${formattedMinutes}:${formattedSeconds}`;
};

const change_message = (idx) => {
  document.querySelector('.message-text').innerHTML = messages[idx - 1];
};

const returnPuzzleImgUrl = (idx) => {
  for (const element of document.styleSheets[1].cssRules) {
    let findRule = '.puzzle' + idx;
    if (element.selectorText == findRule) {
      return element.style.backgroundImage;
    }
  }
};

const returnSolutionImgUrl = (idx) => {
  for (const element of document.styleSheets[1].cssRules) {
    let findRule = '.solution' + idx;
    if (element.selectorText == findRule) {
      return element.style.backgroundImage;
    }
  }
};

function change_bg(idx) {
  for (const element of document.styleSheets[1].cssRules) {
    let findRule = '[class^="tile"]';
    if (element.selectorText == findRule) {
      element.style.backgroundImage = returnPuzzleImgUrl(idx);
      puzzleSolution.src = returnSolutionImgUrl(idx).split('"')[1];
    }
  }
}

function resizeImage() {
  tiles.forEach((tile) => {
    tile.style.backgroundSize = `${puzzleContainer.offsetWidth}px ${puzzleContainer.offsetHeight}px`;
  });
}

function mySplit(array) {
  array = array.split(/(?=\d+)/);
  return array;
}

export { formatTime, change_message, change_bg, mySplit, resizeImage };
