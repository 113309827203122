import { howToPlayButton } from './constants.js';

function getToHowToPlayScreenDOM() {
  document.querySelector('.how-to-play').classList.remove('hide');
  document.querySelector('.puzzle-screen').classList.add('hide');
}

howToPlayButton.addEventListener('click', getToHowToPlayScreenDOM);

function backToMainScreenDOM() {
  document.querySelector('.how-to-play').classList.add('hide');
  document.querySelector('.puzzle-screen').classList.remove('hide');
}

function startGameDOM() {
  document.querySelector('.initial-screen').classList.add('hide');
  document.querySelector('.main-screen').classList.remove('hide');
}

function endGameDOM() {
  document.querySelector('.initial-screen').classList.remove('hide');
  document.querySelector('.main-screen').classList.add('hide');
}

function showMessageDOM() {
  document.querySelector('.puzzle-message').classList.remove('hide');
  document.querySelector('.puzzle').classList.add('hide');
}

function hideMessageDOM() {
  document.querySelector('.puzzle-message').classList.add('hide');
  document.querySelector('.puzzle').classList.remove('hide');
}

function showDisabledMessage() {
  document.querySelector('.disabled-message').classList.remove('hide');
  setTimeout(() => {
    document.querySelector('.disabled-message').classList.add('hide');
  }, 1500);
}

function showSolutionDOM() {
  document.querySelector('.puzzle-solution').classList.remove('hide');
  document.querySelector('.puzzle').classList.add('hide');
}

function hideSolutionDOM() {
  document.querySelector('.puzzle-solution').classList.add('hide');
  document.querySelector('.puzzle').classList.remove('hide');
}

export {
  getToHowToPlayScreenDOM,
  backToMainScreenDOM,
  startGameDOM,
  endGameDOM,
  showMessageDOM,
  hideMessageDOM,
  showDisabledMessage,
  showSolutionDOM,
  hideSolutionDOM,
};
